import React from 'react'
import { Images } from '../../assets/assets'
import './BuyCrypto.css'

const BuyCrypto = () => {
	return (
		<div className='buy-crypto'>
			<div className='crypto-unorder-list'>
				<ul className='img-span-unorder-list d-md-flex justify-content-center  m-0'>
					<li className='img-span-gap d-flex justify-content-center align-items-center mb-2 mb-md-0'>
						<img className='img-fluid' src={Images.home5} alt="home5" />
						<span className=''>BUY CRYPTO WITH A CARD</span>
					</li>
					<li className='img-span-gap d-flex justify-content-center align-items-center mb-2 mb-md-0'>
						<img className='img-fluid' src={Images.home6} alt="home6" />
						<span>EXCHANGE INSTANTLY</span>
					</li>
					<li className='img-span-gap d-flex justify-content-center align-items-center'>
						<img className='img-fluid' src={Images.home7} alt="home7" />
						<span>PRIVATE & SECURE</span>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default BuyCrypto;