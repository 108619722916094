/* eslint-disable */
import { Link, useNavigate } from "react-router-dom"
import { Button, Container, Navbar } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import { toast } from "react-toastify"
import React, { useEffect, useState } from 'react';
import { HttpProvider, JsonRpcClient } from "@concordium/web-sdk";
import { JSON_RPC_URL } from "../../config";
import { ResultAsync } from "neverthrow";
import { detectConcordiumProvider } from "@concordium/browser-wallet-api-helpers";
import './Header.css'
import { useCookies } from "react-cookie";

async function connect(client, setConnectedAccount) {
    const account = await client.connect();
    return setConnectedAccount(account);
}
const rpc = new JsonRpcClient(new HttpProvider(JSON_RPC_URL));

const Header = () => {

    // const [wallet, setWallet] = useState("browserwallet");
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies(['address', 'balance']);
    const [wallet, setWallet] = useState(false);
    const [walletDropdown, showWalletDropdown] = useState(false)
    // Wallet clients: React only manages their existence, not their internal state.
    const [browserwalletClient, setBrowserwalletClient] = useState();

    // Wallet state.
    const [browserwalletConnectedAccount, setBrowserwalletConnectedAccount] = useState();
    async function Disconnect() {
        toast.error("Wallet disconnected")
        setBrowserwalletConnectedAccount();
    }
    // Attempt to initialize Browser Wallet Client.
    useEffect(
        () => {
            console.log("called");
            ResultAsync.fromPromise(
                detectConcordiumProvider()
                    .then(client => {
                        // Listen for relevant events from the wallet.
                        client.on('accountChanged', account => {
                            console.debug('browserwallet event: accountChange', { account });
                            setBrowserwalletConnectedAccount(account);
                        });
                        client.on('accountDisconnected', () => {
                            console.debug('browserwallet event: accountDisconnected');
                            client.getMostRecentlySelectedAccount().then(setBrowserwalletConnectedAccount);
                        });
                        client.on('chainChanged', (chain) => {
                            console.debug('browserwallet event: chainChanged', { chain });
                        });
                        // Check if you are already connected
                        client.getMostRecentlySelectedAccount().then(setBrowserwalletConnectedAccount);
                        return client;
                    }),
                () => "browser wallet did not initialize in time" // promise rejects without message
            )
                .then(setBrowserwalletClient);
        }, [wallet]);

    useEffect(
        () => {
            if (browserwalletConnectedAccount) {
                toast.success("Wallet Connected")
            }

        }, [browserwalletConnectedAccount]
    );

    function e(e) {
        throw new Error('Function not implemented.');
    }

    const handleDisconnect = () => {
        removeCookie('address');
        removeCookie('balance');
        navigate('/')
    };

    return (
        <div className='header'>
            <Container fluid>
                <Navbar expand="lg">
                    <div className=' header-logo d-flex align-items-center'>
                        <Link className="navbar-brand" to="/">
                            <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                        </Link>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <ul className="navbar-nav list-unstyled">
                                <li><Link className="nav-link" to="/games" >Games</Link></li>
                                <li><Link className="nav-link" to="/nft" >NFT</Link></li>
                                {/* <li><Link className="nav-link" to="/token" >Token</Link></li> */}
                                <li><Link className="nav-link" to="/staking" >Staking</Link></li>
                            </ul>
                        </Navbar.Collapse>
                        {/* <div className="eseaking-modal">
                            <Link to="/esealing" className="decoration-none">Esealing</Link>
                            <Link to="/timestamping" className="decoration-none">Timestamping</Link>
                        </div> */}
                    </div>
                    <div className='main-header d-flex justify-content-end align-items-center'>
                        {/* <Button className='header-button'>Developer</Button> */}
                        <ul className='d-flex justify-content-end align-items-center p-0 m-0'>
                            {/* <li className='list-none header-list-icon'>
                                <Button className='list'>
                                    <img className='img-fluid default-img' src={Images.headericon} alt="" />
                                    <img className='img-fluid hover-img' src={Images.headericonhover} alt="" />
                                </Button>
                            </li>
                            <li className=' list-none header-list-icon'>
                                <Button className='list'>
                                    <img className='img-fluid default-img' src={Images.icontwo} alt="" />
                                    <img className='img-fluid hover-img' src={Images.icontwohover} alt="" />
                                </Button>
                            </li> */}
                            {/* {cookies.address !== undefined ? */}
                                <li className='list-none header-list-icon'>
                                    <Button className='list-disconnect' onClick={handleDisconnect}>Disconnect</Button>
                                </li>
                               {/* : <></>
                             } */}
                            {/* <li className='list-none header-list-icon'>
                                <Button className='list'>
                                    <img className='img-fluid default-img' src={Images.iconthree} alt="" />
                                    <img className='img-fluid hover-img' src={Images.iconthreehover} alt="" />
                                </Button>
                            </li> */}
                            {/* <li className='list-none header-list-icon position-relative'>
                                {
                                    browserwalletConnectedAccount ?
                                        <>
                                            <Button className='list'
                                                onClick={() => { showWalletDropdown(!walletDropdown) }}
                                            >
                                                <img className='img-fluid default-img' src={Images.iconfourblue} alt="" />
                                                <img className='img-fluid hover-img' src={Images.iconfourhover} alt="" />
                                            </Button>
                                            <div className={`wallet-dropdown position-absolute ${walletDropdown && browserwalletConnectedAccount ? "d-block" : "d-none"}`}>
                                                <Button onClick={
                                                    browserwalletClient?.match(c => (() => Disconnect()), e)
                                                }>
                                                    Disconnect
                                                </Button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Button className='list' onClick={() => {
                                                browserwalletClient?.match(c => connect(c, setBrowserwalletConnectedAccount)
                                                    , e);
                                                showWalletDropdown(false);
                                            }}
                                            >
                                                <img className='img-fluid default-img' src={Images.iconfour} alt="" />
                                                <img className='img-fluid hover-img' src={Images.iconfourhover} alt="" />
                                            </Button>
                                        </>
                                }

                            </li> */}
                            <li className='list-none header-list-icon'>
                                <Button className='list'>
                                    <img className='img-fluid default-img' src={Images.iconfive} alt="" />
                                    <img className='img-fluid hover-img' src={Images.iconfivehover} alt="" />
                                </Button>
                            </li>
                        </ul>
                    </div>
                </Navbar>
            </Container>
        </div>

    )
}

export default Header
