import React from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './CryptoCurrencies.css'

const CryptoCurrencies = () => {
    return (
        <div className='cryptoc-currencies'>
            <Container>
                <Row>
                    <Col>
                        <div className='main-cryptoc-currencies'>
                            <div className='heading-currencies'>
                                <h4 className='text-white text-center'>Get Easy Access to Daily Utilities</h4>
                                <span className='text-center d-block'>MetaMuto Wallet is for you if you want to</span>
                            </div>
                            <div className='cryptoc-box'>
                                <div className='main-img-box d-md-flex align-items-center'>
                                    <div className='img-box gap-right d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Buy Bitcoin in under five minutes</p>
                                    </div>
                                    <div className='img-box gap-left d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Exchange your crypto without leaving the app</p>
                                    </div>
                                </div>
                                <div className='main-img-box d-md-flex align-items-center'>
                                    <div className='img-box gap-right d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Easily earn interest on the crypto in your wallet</p>
                                    </div>
                                    <div className='img-box gap-left d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Track charts and prices within the app</p>
                                    </div>
                                </div>
                                <div className='main-img-box d-md-flex align-items-center'>
                                    <div className='img-box gap-right d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Hold Your Different Nft Collectibles</p>
                                    </div>
                                    <div className='img-box gap-left d-flex align-items-center'>
                                        <div className='circle-img'>
                                            <img className='img-fluid' src={Images.multybox} alt="multybox" />
                                        </div>
                                        <p>Minimized Extension For Better Accessibility</p>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Button className='btn-custom'>Browse Now</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default CryptoCurrencies;