import React, { Suspense } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import routes from './routes'
import { Routes, Route, } from "react-router-dom";
import './App.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import NotFound from './components/NotFound/NotFound';

const App = (props) => {
  // const navigation = useNavigate();
  // const location = useLocation();

  return (
    <React.Fragment>
      <Suspense>
        <div>
          <Routes>
            {
              routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    element={
                      <route.layout {...props} title={route.title}>
                        <route.component {...props} />
                      </route.layout>
                    }
                  />
                )
              })
            }
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Suspense>
      <ToastContainer />
    </React.Fragment>
  )
}

export default App;