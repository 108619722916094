import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { Images } from '../../assets/assets'
import './Games.css'

const Games = () => {
   return (
      <div className='Games'>
         <Container>
            <Row>
               <Col>
                  <Link className="not-found-logo d-flex justify-content-center align-items-center" to="/">
                     <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                  </Link>
                  <div className='main-heading'>
                     <h1 className='text-white text-center'>COMING SOON</h1>
                     <span className='text-white d-flex justify-content-center'>Our website is under construction, follow us for update now!</span>
                     <div className='main-date-time d-flex justify-content-center align-items-center'>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>35</strong>
                           <span className='text-white text-center d-block'>Day</span>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>:</strong>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>17</strong>
                           <span className='text-white text-center d-block'>hours</span>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>:</strong>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>59</strong>
                           <span className='text-white text-center d-block'>Minutes</span>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>:</strong>
                        </div>
                        <div className='date-time'>
                           <strong className='text-white text-center d-block'>56</strong>
                           <span className='text-white text-center d-block'>Seconds</span>
                        </div>
                     </div>
                  </div>
               </Col>
            </Row>
         </Container>
      </div>
   )
}

export default Games