import React, { useState, useEffect } from "react";
// import {useLocation} from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import Sidebar from "../components/Sidebar/Sidebar";
// import { act } from "react-dom/test-utils";

const Layout1 = (props) => {

    const [active, setActive] = useState(false)
    console.log("active = ", active)
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const { children } = props;
    
    return (
        <div className={`main-wrapper ${active ? "active" : ""}`}>
            <Header />
            {/* <div className={isActive ? "startaccount overview active" : "startaccount overview"}> */}
            <div className="">
                <Container>
                    <div className=' main-content d-flex align-items-start justify-content-between'>
                        <Sidebar active={active} setActive={setActive} />
                        <div className='main-wallet-overview'>
                            {children}
                        </div>
                    </div>
                </Container>
            </div>
            <Footer />
        </div>
    )
}

export default Layout1;