import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { Images } from '../../assets/assets'
import { Button, Tab, Tabs, Table, Modal } from 'react-bootstrap'
import './StakingAccount.css'

function StakingAccount() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className='stakingaccount'>
      <div className='main-staking-heading'>
        <div className='staking-heading'>
          <h3 className='text-white text-center'>Staking</h3>
        </div>
        <Button className='staking-icon d-flex justify-content-center align-items-center'>
          <FontAwesomeIcon icon={faArrowLeftLong} className="text-white" />
        </Button>
      </div>
      <div className='account-tab'>
        <Tabs
          defaultActiveKey="home"
          transition={false}
          id="noanim-tab-example"
          className="main-tabs-account flex-wrap"
        >
          <Tab className='account-tabs mb-3 mb-lg-0 ' eventKey="home" title={<FontAwesomeIcon icon={faLock} />}>
            <div className='main-staking-heading d-flex justify-content-between align-items-center'>
              <div className='staking-heading'>
                <h3 className='text-white'>Locked</h3>
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr className='text-white'>
                  <th className='tabs-table-th'>Token</th>
                  <th className='tabs-table-th'>Game</th>
                  <th className='tabs-table-th'>Invest</th>
                  <th className='tabs-table-th'>Duration</th>
                  <th className='tabs-table-th'>Minimum</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro '>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white line-bottom'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab className='account-tabs mb-3 mb-sm-0' eventKey="transfer" title={<FontAwesomeIcon icon={faUnlock} />}>
            <div className='main-staking-heading d-flex justify-content-between align-items-center'>
              <div className='staking-heading'>
                <h3 className='text-white'>UnLocked</h3>
              </div>
            </div>
            <Table responsive>
              <thead>
                <tr className='text-white'>
                  <th className='tabs-table-th'>Token</th>
                  <th className='tabs-table-th'>Game</th>
                  <th className='tabs-table-th'>Invest</th>
                  <th className='tabs-table-th'>Duration</th>
                  <th className='tabs-table-th'>Minimum</th>
                </tr>
              </thead>
              <tbody>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro '>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
                <tr className='text-white line-bottom'>
                  <td className='euro'>
                    <div className='d-flex align-items-center'>
                      <div className='staking-table-img'>
                        <img src={Images.muto} alt='' className='img-fluid' />
                      </div>
                      <div>muto</div>
                    </div>
                  </td>
                  <td className='euro'>Crypto Box</td>
                  <td className='euro'>7.8%</td>
                  <td className='euro'>
                    <span className='bg-gray-table d-flex justify-content-center align-items-center'>90</span>
                  </td>
                  <td className='euro'>2 muto</td>
                  <td className='table-button euro'>
                    <div className=' d-flex justify-content-end green'>
                      <Button variant="primary" onClick={handleShow}>
                        STAKE
                      </Button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header >
          <Modal.Title>
            <h4 className='modal-heading text-white'>
              LOCKED
            </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='main-staking-data d-flex justify-content-between align-items center'>
            <div className='staking-time'>
              <span className='d-block text-white'>19/04/22</span>
              <span className='d-block text-white text-center'>20:00</span>
            </div>
            <div className='staking-day'>
              <strong className='d-block text-white'>30 days</strong>
            </div>
            <div className='staking-time'>
              <span className='d-block text-white'>19/04/22</span>
              <span className='d-block text-white text-center'>20:00</span>
            </div>
          </div>
          <span className='border-circle d-flex'></span>
          <div className='start-end-date d-flex justify-content-between align-items-center'>
            <span className='text-white d-flex'>Start Date</span>
            <span className='text-white d-flex'>End Date</span>
          </div>
          <h5 className='text-white'>Duration</h5>
          <div className='main-staking-modal d-md-flex justify-content-center align-items-center'>
            <Button className='staking-modal-button me-0 me-md-2'>30 days</Button>
            <Button className='staking-modal-button me-0 me-md-2'>90 dyas</Button>
            <Button className='staking-modal-button'>120 days</Button>
          </div>
          <div className='main-amout-box'>
            <div className='amout-box  d-flex justify-content-between align-items-center'>
              <strong className='d-flex text-white'>AMOUNT:</strong>
              <span className='d-flex'>100</span>
            </div>
            <div className='amout-box  d-flex justify-content-between align-items-center'>
              <strong className='d-flex text-white'>EST. APY</strong>
              <span className='d-flex'>10%</span>
            </div>
            <div className='amout-box  d-flex justify-content-between align-items-center'>
              <strong className='d-flex text-white'>EST. INTEREST</strong>
              <span className='d-flex'>10 muto</span>
            </div>
          </div>
          <h5 className='text-white'>Stake Amount</h5>
          <div className='muto-max'>
            <div className='amout-box  d-flex justify-content-between align-items-center'>
              <strong className='d-flex text-white'>100 muto</strong>
              <span className='d-flex'>MAX</span>
            </div>
          </div>
          <Button className='w-100 confirm'>CONFIRM</Button>
          <div className='main-check-box d-flex justify-content-center align-items-center'>
            <Form.Check aria-label="option 1" className='modal-check-box me-2' />
            <span>I have read and i agree</span>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default StakingAccount;

