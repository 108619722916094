import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn, faPinterestP, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Images } from '../../assets/assets'
import './FooterUnAuth.css'

const FooterUnAuth = () => {
    return (
        <div className='footerunauth'>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='main-footerunauth'>
                            <div className='footerunauth-logo'>
                                <Link className="navbar-brand d-flex" to="/">
                                    <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                                </Link>
                            </div>
                            <div className='main-footerunauth unorder-list'>
                                <ul className='footerunauth unorder-list p-0 m-0 d-flex justify-content-center align-items-center'>
                                    <li className='footerunauth-list-items'>
                                        <Link to="" className='footerunauth-icon text-white d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faFacebookF} />
                                        </Link>
                                    </li>
                                    <li className='footerunauth-list-items'>
                                        <Link to="" className='footerunauth-icon text-white d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </Link>
                                    </li>
                                    <li className='footerunauth-list-items'>
                                        <Link to="" className='footerunauth-icon text-white d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faLinkedinIn} />
                                        </Link>
                                    </li>
                                    <li className='footerunauth-list-items'>
                                        <Link to="" className='footerunauth-icon text-white d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faPinterestP} />
                                        </Link>
                                    </li>
                                    <li className='footerunauth-list-items'>
                                        <Link to="" className='footerunauth-icon text-white d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon icon={faYoutube} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footerunauth-anchr'>
                                <ul className="list-unstyled d-flex justify-content-center align-items-center m-0 flex-wrap">
                                    <li className='footerunauth-list-items-anchr'>
                                        <Link className="nav-link text-white " to="">Developers</Link>
                                    </li>
                                    <li className='footerunauth-list-items-anchr'>
                                        <Link className="nav-link text-white " to="">Services</Link>
                                    </li>
                                    <li className='footerunauth-list-items-anchr'>
                                        <Link className="nav-link text-white " to="">Browser Extension</Link>
                                    </li>
                                    <li className='footerunauth-list-items-anchr'>
                                        <Link className="nav-link text-white " to="">Stablecoins</Link>
                                    </li>
                                    <li className='footerunauth-list-items-anchr'>
                                        <Link className="nav-link text-white" to="">Help Center</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='footerunauth-anchr-text text-center'>
                                <p>© 2023 MetaMuto Wallat. All rights reserved</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FooterUnAuth;