import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './BrowserApps.css'

const BrowserApps = () => {
    return (
        <div className='browser-apps'>
            <Container>
                <div className='crypto-card-max-width'>
                    <Row  className='d-flex align-items-center'>
                        <Col md={6}>
                            <div className='browser-apps-img'>
                                <img className='img-fluid' src={Images.dapp} alt="dapp" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='heading-paragraphe'>
                                <h4 className='text-white'>Staking</h4>
                                <p>With our integrated swap, users can deposit their favourite tokens and get rewards based on their locking period.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default BrowserApps