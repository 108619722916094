// import React from 'react'
import React, { useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faPlus } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { Images } from '../../assets/assets'
import './SwapAccount.css'


const options = [
    {
        value: 'VET', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />VET</span>
        )
    },
    {
        value: 'BANANA', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />BANANA</span>
        )
    },
    {
        value: 'DBET', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />DBET</span>
        )
    },
    {
        value: 'DHN', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />DHN</span>
        )
    },
    {
        value: 'GOLD', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />GOLD</span>
        )
    },
    {
        value: 'HAI', label: (
            <span className='lable-img'><img src={Images.muto} alt=''
                className='img-fluid me-2' />HAI</span>
        )
    },
];
// const optionsone = [
//     { value: 'Past 30 days', label: 'Past 30 days' },
//     { value: 'Post 10 Days', label: 'Post 10 Days' },
//     { value: 'Post 7 Days', label: 'Post 7 Days' },
//     { value: 'Post 5 Days', label: 'Post 5 Days' },
// ];

const SwapAccount = () => {
    const [selectedOption, setSelectedOption] = useState({ value: 'Deposit', label: 'Deposit' });
    // const [selectedOptionOne, setSelectedOptionOne] = useState({ value: 'Past 30 days', label: 'Past 30 days' });

    return (
        <div className='swap-account'>
            <div className='account-tab d-block justify-content-center align-itens-center'>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="main-tabs-account flex-wrap"
                >
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="home" title="Swap">
                        <div className='swap-tab'>
                            <span className='text-white d-flex'>From</span>
                            <div className='main-swap-strong-select d-md-flex justify-content-between align-items-center'>
                                <div className='swap-strong'>
                                    <strong className='text-white d-flex'>0.0</strong>
                                </div>
                                <div className='swap-select'>
                                    <div className='select-swap all-select'>
                                        {/* <div className='select-img'>
                                            <img src={Images.muto} alt='' className='img-fluid' />
                                        </div> */}
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            classNamePrefix="wallet-select"
                                            options={options}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button className='swap-icon d-flex justify-content-center w-100'>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Button>
                            <span className='text-white d-flex'>To (estimated)</span>
                            <div className='main-swap-strong-select d-md-flex justify-content-between align-items-center'>
                                <div className='swap-strong'>
                                    <strong className='text-white d-flex'>0.0</strong>
                                </div>
                                <div className='swap-select'>
                                    <div className='select-swap all-select'>
                                        {/* <div className='select-img'>
                                            <img src={Images.muto} alt='' className='img-fluid' />
                                        </div> */}
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            classNamePrefix="wallet-select"
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='swap-connect'>
                                <Button className='w-100'>Connect Wallet</Button>
                            </div>
                        </div>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="Send">
                        <div className='swap-tab'>
                            <div className='main-swap-strong-select'>
                                <div className='send-strong'>
                                    <strong className='text-white d-flex justify-content-center'>0.0</strong>
                                </div>
                                <div className='swap-select d-flex justify-content-center mb-3'>
                                    <div className='select-swap all-select'>
                                        <Select
                                            defaultValue={selectedOption}
                                            onChange={setSelectedOption}
                                            classNamePrefix="wallet-select"
                                            options={options}
                                            isSearchable={false}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <Button>
                                        <FontAwesomeIcon icon={faPlus} />
                                        <span className='ms-2'>Add a swap</span>
                                    </Button>
                                </div>
                            </div>
                            <span className='recipient text-white d-flex'>Recipient</span>
                            <>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        placeholder="Wallet Address"
                                        aria-label="Username"
                                        className='swap-input'
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                            </>
                            <div className='swap-connect'>
                                <Button className='w-100'>Connect Wallet</Button>
                            </div>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default SwapAccount