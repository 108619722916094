import React, { useEffect, useState } from 'react';
import { Button, Tab, Tabs, Table, Modal, Form, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import './StartAccount.css'
import { useCookies } from 'react-cookie'
import { ethers } from 'ethers';
import Swal from 'sweetalert2';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from 'react-toastify';
import { QRCodeSVG } from 'qrcode.react';

const tooltip = (
    <Tooltip id="tooltip">
        <strong>Click to copy address</strong>
    </Tooltip>
);

const StartAccount = () => {

    const [balance, setBalance] = useState(0);
    const [address, setAddress] = useState(0);
    const [transactionData, setTransactionData] = useState({ receiverAddress: '', amount: '' });
    const [cookies] = useCookies(['address', 'balance']);
    const [errors, setErrors] = useState({ addressError: '', amountError: '' });

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showTwo, setShowTwo] = useState(false);
    const handleCloseTwo = () => setShowTwo(false);
    const handleShowTwo = () => setShowTwo(true);

    useEffect(() => {
        if (cookies.address !== undefined && cookies.balance !== undefined) {
            setAddress(cookies.address.toLowerCase());
            setBalance(cookies.balance);
        }
    }, [cookies.balance, cookies.address]);

    const handleChange = (e) => {
        setTransactionData({ ...transactionData, [e.target.name]: e.target.value })
    }

    const handleTransaction = async () => {
        const { receiverAddress, amount } = transactionData
        if (receiverAddress === '') {
            setErrors({ ...errors, addressError: 'Address is required' })
        } else if (!ethers.utils.isAddress(receiverAddress)) {
            setErrors({ ...errors, addressError: 'Please enter a valid address' })
        } else if (amount === '' || amount === '0') {
            setErrors({ ...errors, amountError: 'Please enter a valid amount' })
        } else {
            setErrors({ ...errors, addressError: '', amountError: '' })
            try {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                await provider.send("eth_requestAccounts", []);
                const signer = provider.getSigner();
                const transaction = {
                    to: transactionData.receiverAddress,
                    value: ethers.utils.parseEther(transactionData.amount),
                };
                const sentTransaction = await signer.sendTransaction(transaction);
                console.log("sentTransaction", sentTransaction);
                Swal.fire('Done!', 'Transfer Successful.', 'success');
                setErrors({ ...transactionData, receiverAddress: '', amount: '' })
                handleCloseTwo()
            } catch (err) {
                console.log(err)
                Swal.fire('Failed!', 'Transaction has been Reverted', 'error');
            }
        }
    }

    const displayMessage = () => {
        toast.success('Copied successfully');
    }

    return (
        <div className='startaccount'>
            <div className='wallet-overview main-tabs d-flex justify-content-between align-items-center'>
                <div className='heading-span'>
                    <div className='wallet-heading d-flex justify-content-between align-items-center flex-column flex-lg-row'>
                        <h3 className="mb-3 mb-lg-0">START ACCOUNT</h3>
                        <Link to='/transaction-history' className='transation-anchr decoration-none text-white'>
                            TRANSACTION HISTORY
                        </Link>
                    </div>
                    <div className='d-flex flex-wrap align-items-end'>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Value Purchase</span>
                            <div className='overview-storng d-flex align-items-center h-100 flex-wrap'>
                                <strong className='pixd'>300.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Value Purchase</span>
                            <div className='overview-storng d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>300.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        <div className='account-pixd'>
                            <div className='overview-storng icon d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>
                                    <FontAwesomeIcon icon={faClock} />
                                </strong>
                                <span className='tusd'>history</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-tab'>
                <Tabs defaultActiveKey="home" transition={false} id="noanim-tab-example" className="main-tabs-account flex-wrap">
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="home" title="TOKEN">
                        <div className='main-start-account-modal d-flex '>
                            <div className='start-account-modal me-2'>
                                <Button variant="primary" onClick={handleShow}>
                                    DEPOSIT
                                </Button>
                                <Modal show={show} onHide={handleClose} animation={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Deposit to wallet</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='qrpcode-span text-center pb-3'>
                                            {/* <span className='text-white d-block text center mb-2'>SCAN</span> */}
                                            <QRCodeSVG height="180" width="180" value={address} />
                                        </div>
                                        <div className='main-button'>
                                            <span className='text-white me-3'>wallet address: {address && '0x...' + (address.substring(39, 42))}</span>
                                            <OverlayTrigger placement="top" overlay={tooltip}>
                                                <CopyToClipboard text={address && address}>
                                                    <Button onClick={displayMessage}>
                                                        <FontAwesomeIcon icon={faCopy} />
                                                    </Button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                        </div>
                                        {/* <div className='main-button'>
                                            <Button>confirm</Button>
                                        </div> */}
                                    </Modal.Body>
                                </Modal>
                            </div>
                            <div className='start-account-modal me-0 me-md-2'>
                                <Button variant="primary" onClick={handleShowTwo}>
                                    WITHDRAW
                                </Button>
                                <Modal show={showTwo} onHide={handleCloseTwo} animation={false}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Transfer</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className='main-button'>
                                            <span className='w-100 text-white text-center'>Available Balance: {balance && parseFloat(balance).toFixed(6)} BNB</span>
                                        </div>
                                        <div className='main-input'>
                                            <Form className='form-border'>
                                                <Form.Group className='mb-3'>
                                                    <Form.Label className='text-white'>Address</Form.Label>
                                                    <Form.Control className='start-input' type='text' name='receiverAddress' placeholder='Enter wallet address' value={transactionData.receiverAddress} onChange={handleChange} />
                                                    <span className='text-danger'>{errors.addressError !== '' ? errors.addressError : ''}</span>
                                                </Form.Group>
                                                <Form.Group className='mb-3 w-100'>
                                                    <Form.Label className='text-white'>Amount</Form.Label>
                                                    <Form.Control className='start-input' type='text' name='amount' placeholder='Enter amount' value={transactionData.amount} onChange={handleChange} />
                                                    <span className='text-danger'>{errors.amountError !== '' ? errors.amountError : ''}</span>
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <div className='main-button'>
                                            <Button onClick={handleTransaction}>Confirm</Button>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Coin</th>
                                    <th className='tabs-table-th'>Total</th>
                                    <th className='tabs-table-th'>Available</th>
                                    <th className='tabs-table-th'>In Orders</th>
                                    <th className='tabs-table-th'>CCD Value</th>
                                    <th className='tabs-table-th text-center'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>BTC</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-center'>
                                            <Button className='blue'>Swap</Button>
                                            {/* <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button> */}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default StartAccount