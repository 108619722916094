import React from 'react'
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './SimpleStap.css'

const SimpleStap = () => {
    return (
        <div className='simple-stap'>
            <Container>
                <div className='crypto-card-max-width'>
                    <Row>
                        <Col md={12}>
                            <div className='main-heading-cryto-card'>
                                <div className='main-heading-paragraphes'>
                                    <div className='heading-paragraphes'>
                                        <h4 className='text-white text-center'>Get Started in 3 simple Steps</h4>
                                        <p className='text-center'>It only takes a few minutes</p>
                                    </div>
                                    <div className='stap-img'>
                                        <img className='img-fluid' src={Images.stapImg} alt="stapImg" />
                                    </div>
                                    <div className='stap-button  d-flex justify-content-center align-items-center'>
                                        <Button className='btn-custom'>Download Now</Button>
                                    </div>
                                </div>
                                <div className='crypto-card-max-width'>
                                    <div className='main-wallet-app'>
                                        <h5 className='text-white text-center'>Get the muto wallet app now!</h5>
                                        <p className='text-center'>The most trusted & secure crypto wallet</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default SimpleStap;