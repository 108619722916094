// import React from 'react';

//components
import Overview from './components/Overview/Overview';
import StartAccount from './components/StartAccount/StartAccount';
import GameAccount from './components/GameAccount/GameAccount';
import StakingAccount from './components/StakingAccount/StakingAccount'
import FiatAccount from './components/FiatAccount/FiatAccount';
import TransactionHistory from './components/TransactionHistory/TransactionHistory'
import Esealing from './components/Esealing/Esealing';
import TradeAccount from './components/TradeAccount/TradeAccount'
import NftAccount from './components/NftAccount/NftAccount'
import SWAPAccount from './components/SwapAccount/SwapAccount'
import NFTListing from './components/NFT/NFTListing';
import Games from './components/Games/Games';
import Staking from './components/Staking/Staking';
import Home from "./components/Home/Home"
// import Connect from './components/ConnectMetaMask/Connect';

//layouts
import Layout1 from './layouts/layout1';
import Layout2 from './layouts/layout2';
import Layout3 from './layouts/layout3';



// routes
const routes = [
    { path: '/', access: true, exact: true, title: 'Home', layout: Layout3, component: Home},
    { path: '/dashboard', access: true, exact: true, title: 'Dashboard', layout: Layout1, component: Overview },
    { path: '/start-account', access: true, exact: true, title: 'Start Account', layout: Layout1, component: StartAccount },
    { path: '/game-account', access: true, exact: true, title: 'Game-Account', layout: Layout1, component: GameAccount },
    { path: '/staking-account', access: true, exact: true, title: 'Staking-Account', layout: Layout1, component: StakingAccount },
    { path: '/fiat-account', access: true, exact: true, title: 'Fiat-Account', layout: Layout1, component: FiatAccount },
    { path: '/transaction-history', access: true, exact: true, title: 'Transaction-History', layout: Layout1, component: TransactionHistory },
    { path: '/esealing', access: true, exact: true, title: 'Esealing', layout: Layout2, component: Esealing },
    { path: '/trade-account', access: true, exact: true, title: 'TradeAccount', layout: Layout1, component: TradeAccount },
    { path: '/nft-account', access: true, exact: true, title: 'NftAccount', layout: Layout1, component: NftAccount },
    { path: '/swap-account', access: true, exact: true, title: 'SWAPAccount', layout: Layout1, component: SWAPAccount },
    { path: '/nft', access: true, exact: true, title: 'NFTListing', layout: Layout2, component: NFTListing },
    { path: '/games', access: true, exact: true, title: 'Games', layout: Layout2, component: Games },
    { path: '/staking', access: true, exact: true, title: 'Staking', layout: Layout2, component: Staking }
];

export default routes;
