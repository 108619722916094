import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn, faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { Images } from '../../assets/assets'
import './Footer.css'
const Footer = () => {
    return (
        <div className='footer'>
            <Container>
                <div className='top-footer'>
                    <Row>
                        <Col>
                            <strong className="footer-logo d-inline-block">
                                <img className='img-fluid ' src={Images.siteLogo} alt=""/>
                            </strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}>
                            <div className='footer-col'>
                                <h3 className='about-us'>About Us</h3>
                                <ul className='p-0 m-0'>
                                    <li className='list-none list-footer'><Link to="#" className='decoration-none'>Contact Us</Link></li>
                                    <li className='list-none list-footer'><Link to="#" className='decoration-none'>Copyright</Link></li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={2}>
                            <div className='footer-col'>
                                <h3 className='about-us'>Products</h3>
                                <ul className='p-0 m-0'>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>NFT</Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to="#" className='decoration-none'>Token</Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to="#" className='decoration-none'>Buy Crypto</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={2}>
                            <div className='footer-col'>
                                <h3 className='about-us'>Service</h3>
                                <ul className='p-0 m-0'>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>Staking</Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'> Trade</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={2}>
                            <div className='footer-col'>
                                <h3 className='about-us'>Support</h3>
                                <ul className='p-0 m-0'>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>Privacy Policy</Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>Terms of Use</Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'> Help Support</Link >
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={2}>
                            <div className='footer-col'>
                                <ul className='p-0 m-0'>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>
                                            <h3 className='about-us investors'>Investors</h3>
                                        </Link>
                                    </li>
                                    <li className='list-none list-footer'>
                                        <Link to='#' className='decoration-none'>
                                            <h3 className='about-us investors'>Investors Portal</h3>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>

                        <Col md={2}>
                            <div className='footer-col'>
                                <h3 className='about-us'>Community</h3>
                                <ul className='p-0 m-0 d-flex flex-wrap footer-unorder-list'>
                                    <li className='list-none list-footer icon'>
                                        <Link to='#' className='decoration-none icon-circle'>
                                            <FontAwesomeIcon icon={faFacebookF} className='icon' />
                                        </Link>
                                    </li>
                                    <li className='list-none list-footer icon'>
                                        <Link to='#' className='decoration-none icon-circle'>
                                            <FontAwesomeIcon icon={faTwitter} className='icon' />
                                        </Link>
                                    </li>
                                    <li className='list-none list-footer icon '>
                                        <Link to='#' className='decoration-none icon-circle'>
                                            <FontAwesomeIcon icon={faPaperPlane} className='icon' />
                                        </Link>
                                    </li>
                                    <li className='list-none list-footer icon'>
                                        <Link to='#' className='decoration-none icon-circle'>
                                            <FontAwesomeIcon icon={faDiscord} className='icon' />
                                        </Link>
                                    </li>
                                    <li className='list-none list-footer icon'>
                                        <Link to='#' className='decoration-none icon-circle'>
                                            <FontAwesomeIcon icon={faLinkedinIn} className='icon' />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className='bottom-footer'>
                    <Row>
                        <Col>
                            <strong className='d-block text-center'>Metamuto © 2022 </strong>
                            <span className='d-block text-center'>By Metamuto Trading S.A. de C.V.</span>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default Footer