import { React, useState, useEffect } from 'react'
import './Overview.css'
// import { Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';

const Overview = () => {

  const navigate = useNavigate();
  const [address, setAddress] = useState(null);
  const [cookies, setCookie, removeCookie] = useCookies(['address', 'balance']);
  const [balance, setBalance] = useState(null);
  const [currenciesList, setCurrenciesList] = useState(null);

  useEffect(() => {
    const fetchCryptos = async () => {
      const endpoint = 'https://api.coingecko.com/api/v3/coins/markets';
      const params = {
        ids: 'binancecoin,bitcoin,ethereum',
        vs_currency: 'usd'
      };
      const response = await axios.get(endpoint, { params });
      const cryptoPrices = response.data.map(crypto => {
        return {
          name: crypto.name,
          symbol: crypto.symbol.toUpperCase(),
          price: crypto.current_price
        };
      });
      setCurrenciesList(cryptoPrices);
    };
    fetchCryptos();
  }, []);

  useEffect(() => {
    if (cookies.address !== undefined && cookies.balance !== undefined) {
      setAddress(cookies.address)
      setBalance(cookies.balance)
    } else {
      navigate('/')
    }
    // eslint-disable-next-line
  }, [cookies.address, cookies.balance]);

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length > 0) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const address = await signer.getAddress();
      const balance = await provider.getBalance(address);
      if (balance) {
        const ethBalance = ethers.utils.formatEther(balance);
        saveAddressToCookie(address, ethBalance);
      }
    } else {
      removeAddressFromCookie();
    }
  };

  useEffect(() => {
    window.ethereum.on("accountsChanged", handleAccountsChanged);
    // eslint-disable-next-line
  }, [])

  const saveAddressToCookie = (address, balance) => {
    setCookie('address', address);
    setCookie('balance', balance);
  };

  const removeAddressFromCookie = () => {
    removeCookie('address');
    removeCookie('balance');
    navigate('/')
  };

  // const handleDisconnect = () => {
  //   removeAddressFromCookie()
  // };

  return (
    <>
      {address ?
        <>
          {/* <Button onClick={handleDisconnect}>Disconnect</Button> */}
          <div className='overview'>
            <div className='wallet-overview d-flex justify-content-between align-items-center'>
              <div className='heading-span'>
                <div className='wallet-heading'>
                  <h3>WALLET OVERVIEW</h3>
                </div>
                <span className='value-span d-flex'>Value Purchase</span>
                <div className='overview-storng d-flex align-items-center flex-wrap'>
                  <strong className='pixd'>
                    Wallet balance: {balance && parseFloat(balance).toFixed(6)} BNB
                  </strong>
                  <span className='tusd'>≈ 22,124.10 TUSD</span>
                  <span className='tusd'>Wallet Address: {address && '0x...' + (address.substring(39, 42))}</span>
                </div>
              </div>
              <div className='circle-span d-sm-flex align-items-center'>
                <span className='circle'> </span>
                <div className='color-list'>
                  <ul className='p-0 m-0'>
                    <li className='list-overview d-flex align-items-center list-m'>
                      <div className=' box-strong d-flex align-items-center'>
                        <span className='border-box jamun-color'></span>
                        <strong className='eth d-flex'>muto</strong>
                      </div>
                      <span className='one-number'>$ 123,864,234.11</span>
                    </li>
                    {currenciesList && currenciesList.map((item, index) => {
                      return (
                        <li className='list-overview d-flex align-items-center list-m' key={index}>
                          <div className=' box-strong d-flex align-items-center'>
                            <span className='border-box green-color '></span>
                            <strong className='eth d-flex'>{item.symbol}</strong>
                          </div>
                          <span className='one-number'>$ {(item.price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            {/* <h3 className='text-white accounts d-flex'>Accounts</h3>
            <div className='d-flex account-main flex-wrap'>
              <div className='start-box text-white'>
                <h5>Start Account</h5>
                <div className='overview-account d-flex align-items-center'>
                  <strong className='five-busd'>50,000 BUSD</strong>
                  <span className='three-pixp'>≈3,000,000.00 muto</span>
                </div>
                <Button className='transfer'>Transfer</Button>
              </div>

              <div className='start-box text-white'>
                <h5>Game Account</h5>
                <div className='overview-account d-flex align-items-center'>
                  <strong className='five-busd'>50,000 BUSD</strong>
                  <span className='three-pixp'>≈3,000,000.00 muto</span>
                </div>
                <Button className='transfer'>Transfer</Button>
              </div>

              <div className='start-box text-white'>
                <h5>Staking Account</h5>
                <div className='overview-account d-flex align-items-center'>
                  <strong className='five-busd'>50,000 BUSD</strong>
                  <span className='three-pixp'>≈3,000,000.00 muto</span>
                </div>
                <Button className='transfer'>Transfer</Button>
              </div>

              <div className='start-box text-white'>
                <h5>Fiat Account</h5>
                <div className='overview-account d-flex align-items-center'>
                  <strong className='five-busd'>50,000 BUSD</strong>
                  <span className='three-pixp'>≈3,000,000.00 muto</span>
                </div>
                <Button className='transfer'>Transfer</Button>
              </div>
            </div> */}
          </div>
        </>
        :
        <></>
      }
    </>
  )
}

export default Overview