import siteLogo from "../assets/images/logo.svg"
import headericon from '../assets/images/header-icon-one.svg'
import headericonhover from '../assets/images/header-icon-one-hover.svg'
import icontwo from '../assets/images/header-icon-two.svg'
import icontwohover from '../assets/images/header-icon-two-hover.svg'
import iconthree from '../assets/images/header-icon-three.svg'
import iconthreehover from '../assets/images/header-icon-three-hover.svg'
import iconfour from '../assets/images/header-icon-four.svg'
import iconfourblue from '../assets/images/header-icon-four-blue.svg'
import iconfourhover from '../assets/images/header-icon-four-hover.svg'
import iconfive from '../assets/images/header-icon-five.svg'
import iconfivehover from '../assets/images/header-icon-five-hover.svg'
import muto from '../assets/images/muto.png'
import nftone from '../assets/images/nftone.png'
import pageFound from '../assets/images/not-found.png'
import bannerImg from '../assets/images/banner-img2.png'
import home1 from '../assets/images/home-1.png'
import home2 from '../assets/images/home-2.png'
import home3 from '../assets/images/home-3.png'
import home4 from '../assets/images/home-4.png'
import home5 from '../assets/images/home-5.png'
import home6 from '../assets/images/home-6.png'
import home7 from '../assets/images/home-7.png'
import multybox from '../assets/images/multy-box.png'
import cryptocard from '../assets/images/crypo-card.png'
import excchange from '../assets/images/excchange-img.png'
import privateImg from '../assets/images/private-img.png'
import dapp from '../assets/images/dapp-img.png'
import stapImg from '../assets/images/stap-img.png'

export const Images = {
    siteLogo,
    headericon,
    headericonhover,
    icontwo,
    icontwohover,
    iconthree,
    iconthreehover,
    iconfour,
    iconfourhover,
    iconfive,
    iconfivehover,
    iconfourblue,
    muto,
    nftone,
    pageFound,
    bannerImg,
    home1,
    home2,
    home3,
    home4,
    home5,
    home6,
    home7,
    multybox,
    cryptocard,
    excchange,
    privateImg,
    dapp,
    stapImg,

}