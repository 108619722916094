import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './PrivateSecure.css'

const PrivateSecure = () => {
    return (
        <div className='private-secure'>
            <Container>
            <div className='crypto-card-max-width'>
                    <Row className='d-flex align-items-center'>
                        <Col md={6}>
                        <div className='heading-paragraphe'>
                                <h4 className='text-white'>Private & Secure</h4>
                                <p>We allow users to have access to their own information and keys. This non-custodial wallet is only accessible by the users themselves.</p>
                            </div>
                        </Col>
                        <Col md={6}>
                        <div className='private-secure-img'>
                                <img className='img-fluid' src={Images.privateImg} alt="privateImg" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default PrivateSecure;