/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Link} from "react-router-dom"
import {Container, Navbar } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './HeaderUnAuth.css'

const HeaderUnAuth = () => {
    return (
        <div className='headerunautha'>
            <Container>
                <Navbar expand="lg" className=' header-logo d-flex align-items-center justify-content-between'>
                    <div className='header-logo'>
                        <Link className="navbar-brand d-flex" to="/">
                            <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                        </Link>
                    </div>
                    <div>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <ul className="navbar-nav list-unstyled">
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >Assets</Link></li>
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >Staking</Link></li>
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >Browser Extension</Link></li>
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >NFTs</Link></li>
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >App Browser</Link></li>
                                <li className='headerunautha-gap'><Link className="nav-link" to="#" >Language</Link></li>

                            </ul>
                        </Navbar.Collapse>
                    </div>
                </Navbar>
            </Container>

        </div>
    )
}
export default HeaderUnAuth;