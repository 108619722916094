import React from 'react'
import { Button, Tab, Tabs, Table } from 'react-bootstrap'
import './GameAccount.css'
import { Link } from 'react-router-dom';

const GameAccount = () => {
    return (
        <div className='gameaccount'>
            <div className='wallet-overview main-tabs d-flex justify-content-between align-items-center'>
                <div className='heading-span'>
                    <div className='wallet-heading d-flex justify-content-between align-items-start align-items-lg-end flex-column flex-lg-row'>
                        <h3 className="mb-3 mb-lg-0">GAME ACCOUNT</h3>
                        <Link to='/transaction-history' className='transation-anchr decoration-none text-white'>
                            TRANSACTION HISTORY
                        </Link>
                    </div>
                    <div className='value-game-account d-flex flex-wrap align-items-end'>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Estimated balance</span>
                            <div className='overview-storng d-flex align-items-center h-100 flex-wrap'>
                                <strong className='pixd'>300.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Available balance</span>
                            <div className='overview-storng d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>300.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex flex-wrap align-items-end'>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Insurance holding</span>
                            <div className='overview-storng d-flex align-items-center h-100 flex-wrap'>
                                <strong className='pixd'>4.000.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Insurance fee income</span>
                            <div className='overview-storng d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>4.000.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='account-tab'>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="main-tabs-account flex-wrap"
                >
                    <Tab className='account-tabs' eventKey="home" title="DEPOSIT">
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Coin</th>
                                    <th className='tabs-table-th'>Total</th>
                                    <th className='tabs-table-th'>Available</th>
                                    <th className='tabs-table-th'>Pending</th>
                                    <th className='tabs-table-th'>CCD Value</th>
                                    <th className='tabs-table-th'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>CCD</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="TRANSFER">
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Coin</th>
                                    <th className='tabs-table-th'>Total</th>
                                    <th className='tabs-table-th'>Available</th>
                                    <th className='tabs-table-th'>In Orders</th>
                                    <th className='tabs-table-th'>muto Value</th>
                                    <th className='tabs-table-th'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>muto</td>
                                    <td className='euro'>300 muto</td>
                                    <td className='euro'>1.000.000 muto</td>
                                    <td className='euro'>4.000.000 muto</td>
                                    <td className='euro'>10%</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='green'>Deposit</Button>
                                            <Button className='red'>Withdraw</Button>
                                            <Button className='bule'>Transfer</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    {/* <Tab className='account-tabs mb-3 mb-sm-0' eventKey="contact" title="COIN">
                     <div className='tabs-button flex-wrap'>
                            <Button className='button-tab  mb-3 mb-sm-0'>DEPOSIT</Button>
                            <Button className='button-tab  mb-3 mb-sm-0'>WITHDRAW</Button>
                            <Button className='button-tab  mb-3 mb-sm-0'>BUY</Button>
                            <Button className='button-tab  mb-3 mb-sm-0'>TRANSFER</Button>
                            <Button className='button-tab  mb-3 mb-sm-0'>SWAP</Button>
                        </div>   
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Coin</th>
                                    <th className='tabs-table-th'>Total</th>
                                    <th className='tabs-table-th'>Available</th>
                                    <th className='tabs-table-th'>In Orders</th>
                                    <th className='tabs-table-th'>CCD Value</th>
                                    <th className='tabs-table-th'>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>BTC</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>PUSD</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>LUNA</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>LUNA</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>LUNA</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>LUNA</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end' >
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>LUNA</td>
                                    <td className='euro'>50 000</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>34 000</td>
                                    <td className='euro'>3 000</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>
                                            <Button className='blue'>Swap</Button>
                                            <Button className='red'>Trade</Button>
                                            <Button className='green'>Buy</Button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab> */}
                </Tabs>
            </div>
        </div>
    )
}

export default GameAccount