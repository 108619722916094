import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './CryptoCard.css'

const CryptoCard = () => {
    return (
        <div className='crypto-card'>
            <Container>
                <div className='crypto-card-max-width'>
                    <Row className='d-flex align-items-center'>
                        <Col md={6}>
                            <div className='heading-paragraphe'>
                                <h4 className='text-white'>Buy Crypto with Card</h4>
                                <p>Now users can buy crypto with our integrated on-ramp fiat to crypto solutions with ease and access.</p>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='crypto-card-img'>
                                <img className='img-fluid' src={Images.cryptocard} alt="cryptocard" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default CryptoCard