import React from 'react';
// import { Button, Tab, Tabs } from 'react-bootstrap'
import './Esealing.css'
// import { Link } from 'react-router-dom';

function Esealing() {

    return (
        <div className="esealing d-flex justify-content-center align-items-center">
            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className="main-anchr">
                    <div className="esealing-anchr d-sm-flex justify-content-between flex-wrap">
                        <Link className="decoration-none header-body d-block" to=''>Use Browser Wallet</Link>
                        <Link className="decoration-none header-body d-block" to=''>Use Wallet Connect</Link>
                    </div>
                </div>
                <span className="waiting-span d-flex justify-content-center">Waiting for connection...</span>
                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version text-white">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link className="text-white" to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className="main-anchr">
                    <div className="esealing-anchr d-md-flex justify-content-between">
                        <Link className="decoration-none header-body" to=''>Use Browser Wallet</Link>
                        <Link className="decoration-none header-body" to=''>Use Wallet Connect</Link>
                    </div>
                    <div className="button-mobile">
                        <Button className="registereds w-100" disabled>Connect Browser Wallet</Button>
                    </div>
                </div>
                <span className="waiting-span d-flex justify-content-center">Waiting for connection...</span>
                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version text-white">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link className="text-white" to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className="main-anchr">
                    <div className="esealing-anchr d-md-flex justify-content-between">
                        <Link className="decoration-none header-body d-block" to=''>Use Browser Wallet</Link>
                        <Link className="decoration-none header-body d-block" to=''>Use Wallet Connect</Link>
                    </div>
                    <div className="button-mobile">
                        <Button className="registereds w-100" disabled>Connect Mobile Wallet</Button>
                    </div>
                </div>
                <span className="waiting-span d-flex justify-content-center">Waiting for connection...</span>
                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version text-white">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link className="text-white" to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className='main-disconnect d-flex align-items-center flex-wrap'>
                    <Button>Disconnect Browser Wallet</Button>
                    <span className='text-white'>Use Wallet Connect</span>
                </div>
                <div className='disconnect-span'>
                    <span className='connected d-block'>Connected to:</span>
                    <span className='text-white title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                </div>
                <div className='account-tab'>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="main-tabs-account esealing-tabs flex-wrap"
                    >
                        <Tab className='account-tabs' eventKey="home" title="Registered Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Upload a file</button>
                                    <input type="file" name="myfile" />
                                    <span>No file chosen</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <div className='span-file'>
                                    <span className='d-block'>File Hash of Selected file:</span>
                                </div>
                            </div>
                            <div className="button-mobile registered">
                                <Button className="registereds w-100">Registered File Hash</Button>
                            </div>
                            <div className='esealing-strong-span'>
                                <strong className='d-block text-white'>Transaction Status</strong>
                                <span className='d-block'>None</span>
                            </div>
                        </Tab>
                        <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="Display Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Upload a file</button>
                                    <input type="file" name="myfile" />
                                    <span>No file chosen</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <span className='d-block'>File Hash of Selected file:</span>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className='main-disconnect d-flex align-items-center'>
                    <Button>Disconnect Browser Wallet</Button>
                    <span className='text-white'>Use Wallet Connect</span>
                </div>
                <div className='disconnect-span'>
                    <span className='connected d-block'>Connected to:</span>
                    <span className='text-white title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                </div>

                <div className='account-tab'>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="main-tabs-account esealing-tabs flex-wrap"
                    >
                        <Tab className='account-tabs' eventKey="home" title="Registered Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Upload a file</button>
                                    <input type="file" name="myfile" />
                                    <span>image342145.png</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <div className='span-file'>
                                    <span className='d-block'>File Hash of Selected file:</span>
                                    <span className='title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                                </div>

                            </div>
                            <div className="button-mobile registered">
                                <Button className="registereds w-100">Registered File Hash</Button>
                            </div>
                            <div className='esealing-strong-span'>
                                <strong className='d-block text-white'>Transaction Status</strong>
                                <span className='d-block'>None</span>
                            </div>
                        </Tab>
                        <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="Display Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Choose File</button>
                                    <input type="file" name="myfile" />
                                    <span>No file chosen</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <div className='span-file'>
                                    <span className='d-block'>File Hash of Selected file:</span>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

            {/* <div className='esealing-main'>
                <div className='esealing-heading'>
                    <h4 className='heading-h4 text-center d-flex text-white'>Register a file on Concordium</h4>
                </div>
                <div className='main-disconnect d-flex align-items-center'>
                    <Button>Disconnect Browser Wallet</Button>
                    <span className='text-white'>Use Wallet Connect</span>
                </div>
                <div className='disconnect-span'>
                    <span className='connected d-block'>Connected to:</span>
                    <span className='text-white title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                </div>

                <div className='account-tab'>
                    <Tabs
                        defaultActiveKey="home"
                        transition={false}
                        id="noanim-tab-example"
                        className="main-tabs-account esealing-tabs flex-wrap"
                    >
                        <Tab className='account-tabs' eventKey="home" title="Registered Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Upload a file</button>
                                    <input type="file" name="myfile" />
                                    <span>image342145.png</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <div className='span-file'>
                                    <span className='d-block'>File Hash of Selected file:</span>
                                    <span className='title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                                </div>

                            </div>
                            <div className="button-mobile registered">
                                <Button className="registereds w-100">Registered File Hash</Button>
                            </div>
                            <div className='esealing-strong-span'>
                                <strong className='d-block text-white'>Transaction Status</strong>
                                <span className='d-block'>None</span>
                            </div>
                        </Tab>
                        <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="Display Tab">
                            <div className='main-input-file'>
                                <label className="d-flex">Select a file:</label>
                                <div class="upload-btn-wrapper">
                                    <button class="btn">Choose File</button>
                                    <input type="file" name="myfile" />
                                    <span>No file chosen</span>
                                </div>
                            </div>
                            <div className='compute-file'>
                                <Button className='w-100'>Compute File Hash</Button>
                                <div className='span-file'>
                                    <span className='d-block'>File Hash of Selected file:</span>
                                    <span className='title'>2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW</span>
                                </div>
                                <div className='span-file'>
                                    <span className='d-block'>On-chain Record:</span>
                                    <span className='title'>
                                        2xCbUV4LT2CQnquGWsbj9mmfhmaWrzhixNCUU1JJ4AQyrN7sAW (Witness)2023-02-08T05:46:09.500+00:00 (Timstamp)
                                    </span>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>

                <div className='esealing-border-top'>
                    <div className='span-anchr'>
                        <span className="footer-version">Version: 1.1.0 | </span>
                        <span className="footer-version ">
                            <Link to=''>
                                Learn more about the eSealing service here
                            </Link>
                        </span>
                    </div>
                </div>
            </div> */}

        </div>
    )
}

export default Esealing;