import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import './Sidebar.css'

const Sidebar = ({setActive, active}) => {
    const toggleClass = () => {
        setActive(!active);
    };
    return (
        <aside id="sidebar">
            <div className='overview-list'>
                <button className='toggle-icon' onClick={toggleClass} >
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <ul className='p-0 m-0'>
                    <li className='list-none box-span'>
                        <Link to='/dashboard' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>OVERVIEW</span>
                        </Link>
                    </li>
                    <li className='list-none box-span '>
                        <Link to='/start-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>START ACCOUNT</span>
                        </Link>
                    </li>
                    {/* <li className='list-none box-span'>
                        <Link to='/game-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>GAME ACCOUNT</span>
                        </Link>
                    </li> */}
                    <li className='list-none box-span'>
                        <Link to='/staking-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>STAKING ACCOUNT</span>
                        </Link>
                    </li>
                    <li className='list-none box-span'>
                        <Link to='/trade-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>TRADE ACCOUNT</span>
                        </Link>
                    </li>
                    <li className='list-none box-span'>
                        <Link to='/nft-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>NFT ACCOUNT</span>
                        </Link>
                    </li>
                    <li className='list-none box-span'>
                        <Link to='/swap-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>SWAP ACCOUNT</span>
                        </Link>
                    </li>
                    {/* <li className='list-none box-span'>
                        <Link to='/fiat-account' className=' d-flex align-items-center decoration-none'>
                            <span className='overview-box'></span>
                            <span className='heading-overview'>FIAT ACCOUNT</span>
                        </Link>
                    </li> */}
                </ul>
            </div>
        </aside>
    )
}

export default Sidebar