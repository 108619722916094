import React from 'react'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Images } from '../../assets/assets'
import { Button, Table } from 'react-bootstrap'
// import { Link } from 'react-router-dom';
import './NftAccount.css'

const NftAcount = () => {
  const [show, setshow] = useState(false)
  const datahandler = () => {
    setshow(!show)
  }

  const [show1, setshow1] = useState(false)
  const datahandler1 = () => {
    setshow1(!show1)
  }

  const [show2, setshow2] = useState(false)
  const datahandler2 = () => {
    setshow2(!show2)
  }

  const [show3, setshow3] = useState(false)
  const datahandler3 = () => {
    setshow3(!show3)
  }

  return (
    <div className='nft-acount'>
      <div className='wallet-overview main-tabs d-flex justify-content-between align-items-center'>
        <div className='heading-span'>
          <div className='wallet-heading d-flex justify-content-between align-items-start align-items-lg-end flex-column flex-lg-row'>
            <h3 className="mb-3 mb-lg-0">NFT ACCOUNT</h3>
          </div>
          <div className='d-flex flex-wrap align-items-end'>
            <div className='account-pixd'>
              <span className='value-span d-flex'>Value Purchase</span>
              <div className='overview-storng d-flex align-items-center h-100 flex-wrap'>
                <strong className='pixd'>300.000 muto</strong>
                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
              </div>
            </div>
            <div className='account-pixd'>
              <span className='value-span d-flex'>Selling Value</span>
              <div className='overview-storng d-flex align-items-center flex-wrap'>
                <strong className='pixd'>400.000 muto</strong>
                <span className='tusd'>≈ 5,832,194,124.10 TUSD</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='nft-arrow-table'>
        <div className='main-nfts table-responsive'>
          <ul className='heading-sapn d-flex '>
            <li className='nft-width'>
              <span>Game</span>
            </li>
            <li className='nft-width'>
              <span>NFT quantity</span>
            </li>
            <li className='nft-width'>
              <span>Total purchase valuet</span>
            </li>
            <li className='nft-width'>
              <span>Start Date</span>
            </li>
            <li className='nft-width'>
              <span>Earning</span>
            </li>
            <li className='nft-width'>
              <span>Expenses</span>
            </li>
          </ul>
          <div className='nft-table-open'>
            <ul className='main-nft-bg d-flex align-items-center'>
              <li className='nft-width text-white'>
                <div className='nfts-img'>
                  <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                </div>
              </li>
              <li className='nfts-width text-white'>
                <span>30</span>
              </li>
              <li className='nfts-width text-white'>
                <span>300.000 PIXP</span>
              </li>
              <li className='nfts-width text-white'>
                <span>01/03/2023</span>
              </li>
              <li className='nfts-width text-white'>
                <span>50.000 PIXP</span>
              </li>
              <li className='nfts-width text-white d-flex justify-content-between align-items-center'>
                <span>25.000 PIXP</span>
                <span className='nft-icon d-flex justify-content-center align-items-center' onClick={datahandler}><FontAwesomeIcon className={` animate-icon ${show ? "active" : ""}`} icon={faChevronDown} /></span>
              </li>
            </ul>
          </div>
        </div>
        {show && <div className='table-main-nft text-white d-flex align-items-center'>
          <Table responsive>
            <thead>
              <tr className='text-white'>
                <th className='tabs-table-th'>NFT</th>
                <th className='tabs-table-th'>Purchase value</th>
                <th className='tabs-table-th'>Purchase date</th>
                <th className='tabs-table-th'>Sold date</th>
                <th className='tabs-table-th'>Earning</th>
                <th className='tabs-table-th text-center'>Expenses</th>
                <th className='tabs-table-th'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>}
      </div>
      <div className='nft-arrow-table'>
        <div className='main-nfts table-responsive'>
          <div className='nft-table-open'>
            <ul className='main-nft-bg d-flex align-items-center'>
              <li className='nft-width text-white'>
                <div className='nfts-img'>
                  <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                </div>
              </li>
              <li className='nfts-width text-white'>
                <span>30</span>
              </li>
              <li className='nfts-width text-white'>
                <span>300.000 PIXP</span>
              </li>
              <li className='nfts-width text-white'>
                <span>01/03/2023</span>
              </li>
              <li className='nfts-width text-white'>
                <span>50.000 PIXP</span>
              </li>
              <li className='nfts-width text-white d-flex justify-content-between align-items-center'>
                <span>25.000 PIXP</span>
                <span className='nft-icon d-flex justify-content-center align-items-center' onClick={datahandler1}><FontAwesomeIcon className={` animate-icon ${show ? "active" : ""}`} icon={faChevronDown} /></span>
              </li>
            </ul>
          </div>
        </div>
        {show1 && <div className='table-main-nft text-white d-flex align-items-center'>
          <Table responsive>
            <thead>
              <tr className='text-white'>
                <th className='tabs-table-th'>NFT</th>
                <th className='tabs-table-th'>Purchase value</th>
                <th className='tabs-table-th'>Purchase date</th>
                <th className='tabs-table-th'>Sold date</th>
                <th className='tabs-table-th'>Earning</th>
                <th className='tabs-table-th text-center'>Expenses</th>
                <th className='tabs-table-th'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>}
      </div>
      <div className='nft-arrow-table'>
        <div className='main-nfts table-responsive'>
          <div className='nft-table-open'>
            <ul className='main-nft-bg d-flex align-items-center'>
              <li className='nft-width text-white'>
                <div className='nfts-img'>
                  <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                </div>
              </li>
              <li className='nfts-width text-white'>
                <span>30</span>
              </li>
              <li className='nfts-width text-white'>
                <span>300.000 PIXP</span>
              </li>
              <li className='nfts-width text-white'>
                <span>01/03/2023</span>
              </li>
              <li className='nfts-width text-white'>
                <span>50.000 PIXP</span>
              </li>
              <li className='nfts-width text-white d-flex justify-content-between align-items-center'>
                <span>25.000 PIXP</span>
                <span className='nft-icon d-flex justify-content-center align-items-center' onClick={datahandler2}><FontAwesomeIcon className={` animate-icon ${show ? "active" : ""}`} icon={faChevronDown} /></span>
              </li>
            </ul>
          </div>
        </div>
        {show2 && <div className='table-main-nft text-white d-flex align-items-center'>
          <Table responsive>
            <thead>
              <tr className='text-white'>
                <th className='tabs-table-th'>NFT</th>
                <th className='tabs-table-th'>Purchase value</th>
                <th className='tabs-table-th'>Purchase date</th>
                <th className='tabs-table-th'>Sold date</th>
                <th className='tabs-table-th'>Earning</th>
                <th className='tabs-table-th text-center'>Expenses</th>
                <th className='tabs-table-th'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>}
      </div>
      <div className='nft-arrow-table'>
        <div className='main-nfts table-responsive'>
          <div className='nft-table-open'>
            <ul className='main-nft-bg d-flex align-items-center'>
              <li className='nft-width text-white'>
                <div className='nfts-img'>
                  <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                </div>
              </li>
              <li className='nfts-width text-white'>
                <span>30</span>
              </li>
              <li className='nfts-width text-white'>
                <span>300.000 PIXP</span>
              </li>
              <li className='nfts-width text-white'>
                <span>01/03/2023</span>
              </li>
              <li className='nfts-width text-white'>
                <span>50.000 PIXP</span>
              </li>
              <li className='nfts-width text-white d-flex justify-content-between align-items-center'>
                <span>25.000 PIXP</span>
                <span className='nft-icon d-flex justify-content-center align-items-center' onClick={datahandler3}><FontAwesomeIcon className={` animate-icon ${show ? "active" : ""}`} icon={faChevronDown} /></span>
              </li>
            </ul>
          </div>
        </div>
        {show3 && <div className='table-main-nft text-white d-flex align-items-center'>
          <Table responsive>
            <thead>
              <tr className='text-white'>
                <th className='tabs-table-th'>NFT</th>
                <th className='tabs-table-th'>Purchase value</th>
                <th className='tabs-table-th'>Purchase date</th>
                <th className='tabs-table-th'>Sold date</th>
                <th className='tabs-table-th'>Earning</th>
                <th className='tabs-table-th text-center'>Expenses</th>
                <th className='tabs-table-th'>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
              <tr className='text-white'>
                <td className='euro'>
                  <div className='nfts-img'>
                    <img className='img-fluid' src={Images.nftone} alt="Site Logo" />
                  </div>
                </td>
                <td className='euro'>700 muto</td>
                <td className='euro'>30/3/2022</td>
                <td className='euro'>-----</td>
                <td className='euro'>170 muto</td>
                <td className='euro'>50 muto</td>
                <td className='table-button euro'>
                  <div className='action-buttons d-flex justify-content-center'>
                    <Button className='green'>Sell</Button>
                    <Button className='blue'>Bid</Button>
                    <Button className='red'>Stake</Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>}
      </div>
    </div >
  )
}

export default NftAcount