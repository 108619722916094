/* eslint-disable */
import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './TrustedMillions.css'

const TrustedMillions = () => {
    return (
        <div className='trusted-millions'>
            <Container>
                <Row>
                    <Col md={6}>
                        <div className='main-trusted-millions'>
                            <h2 className='text-white'>A Revolutionized Yet Sophisticated Wallet Technology by MetaMuto.</h2>
                            <p>Stake, Unstake, Swap and hold different tokens. MetaMuto Wallets allows our user on the VeChain protocol to perform all their DeFi acitivities under one roof.</p>
                            <Button className='btn-custom'>Create Wallet</Button>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className='main-trusted-millions-img'>
                            <img className='img-fluid' src={Images.bannerImg} alt="banner-img" />
                        </div>
                    </Col>
                </Row>
            </Container>

        </div>
    )
}
export default TrustedMillions;