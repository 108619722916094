import React from 'react';
import TrustedMillions from '../TrustedMillions/TrustedMillions';
import BuyCrypto from '../BuyCrypto/BuyCrypto'
import CryptoCurrencies from '../CryptoCurrencies/CryptoCurrencies'
import CryptoCard from '../CryptoCard/CryptoCard'
import ExcchangeInstantly from '../ExcchangeInstantly/ExcchangeInstantly'
import PrivateSecure from '../PrivateSecure/PrivateSecure'
import BrowserApps from '../BrowserApps/BrowserApps'
import SimpleStap from '../SimpleStap/SimpleStap'
import './Home.css';
function Home(props) {

	return (
		<div className='home-page'>
			<TrustedMillions />
			<BuyCrypto />
			<CryptoCurrencies />
			<CryptoCard />
			<ExcchangeInstantly />
			<PrivateSecure />
			<BrowserApps />
			<SimpleStap />
		</div>
	);
}

export default Home;