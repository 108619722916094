import React, { useEffect, useState } from 'react'
import { Button, Table } from 'react-bootstrap'
import './TradeAccount.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useCookies } from 'react-cookie'

const TradeAccount = () => {

    const [balance, setBalance] = useState(0);
    const [currenciesList, setCurrenciesList] = useState(null);
    const [cookies] = useCookies(['balance']);

    useEffect(() => {
        if (cookies.balance !== undefined && cookies.balance !== '') {
            setBalance(cookies.balance)
        }
    }, [cookies.balance]);

    useEffect(() => {
        const fetchCryptos = async () => {
            const endpoint = 'https://api.coingecko.com/api/v3/coins/markets';
            const params = {
                vs_currency: 'usd',
                order: 'market_cap_desc',
                per_page: 100,
                page: 1,
                sparkline: false,
                include_24hr_vol: true
            };
            const response = await axios.get(endpoint, { params });
            setCurrenciesList(response.data);
        };
        fetchCryptos();
    }, []);

    const getNumber = (num) => {
        return (Math.abs(Number(num)) / 1.0e+6).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }

    return (
        <div className='trade-account'>
            <div className='wallet-overview main-tabs d-flex justify-content-between align-items-center'>
                <div className='heading-span'>
                    <div className='wallet-heading d-flex justify-content-between align-items-center flex-column flex-lg-row'>
                        <h3 className="mb-3 mb-lg-0">TRADE ACCOUNT</h3>
                        <Link to='/transaction-history' className='transation-anchr decoration-none text-white'>
                            TRANSACTION HISTORY
                        </Link>
                    </div>
                    <div className='d-flex flex-wrap align-items-end'>
                        <div className='account-pixd'>
                            <span className='value-span d-flex'>Estimated balance</span>
                            <div className='overview-storng d-flex align-items-center h-100 flex-wrap'>
                                <strong className='pixd'>{balance && parseFloat(balance).toFixed(6)} BNB</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        {/* <div className='account-pixd'>
                            <span className='value-span d-flex'>Value Purchase</span>
                            <div className='overview-storng d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>300.000 muto</strong>
                                <span className='tusd'>≈ 3,832,194,124.10 TUSD</span>
                            </div>
                        </div>
                        <div className='account-pixd'>
                            <div className='overview-storng icon d-flex align-items-center flex-wrap'>
                                <strong className='pixd'>
                                    <FontAwesomeIcon icon={faClock} />
                                </strong>
                                <span className='tusd'>history</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
            <Table responsive>
                <thead>
                    <tr className='text-white'>
                        <th className='tabs-table-th'>Name</th>
                        <th className='tabs-table-th'>Price</th>
                        <th className='tabs-table-th'>24h Change</th>
                        <th className='tabs-table-th'>24h Volume</th>
                        <th className='tabs-table-th'>Market Cap</th>
                        <th className='tabs-table-th'>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currenciesList && currenciesList.map((item, index) => {
                        return (
                            <tr className='text-white' key={index}>
                                <td className='euro'><img src={item.image} className='img-fluid pe-2' alt='logo' width='40' /> {item.name}</td>
                                <td className='euro'>$ {item.current_price?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                                <td className={item.price_change_percentage_24h < 0 ? "euro text-danger" : "euro text-success"}>
                                    {item.price_change_percentage_24h?.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %
                                </td>
                                <td className='euro'>{getNumber(item.total_volume)} M</td>
                                <td className='euro'>$ {getNumber(item.market_cap)} M</td>
                                <td className='table-button euro'>
                                    <div className='action-buttons d-flex justify-content-end'>
                                        <Button className='blue'>Swap</Button>
                                        <Button className='red'>Trade</Button>
                                        <Button className='green'>Buy</Button>
                                    </div>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </div>
    )
}

export default TradeAccount