import React from 'react'
import { Col, Row, Container, Button } from 'react-bootstrap'
import { Link } from "react-router-dom"
import { Images } from '../../assets/assets'
import './NotFound.css'

const NotFound = () => {
   return (
      <div className='not-fount-main'>
         <Container>
            <Row>
               <Col>
                  <Link className="not-found-logo d-inline-block" to="/">
                     <img className='img-fluid' src={Images.siteLogo} alt="Site Logo" />
                  </Link>
                  <div className='man-img d-flex justify-content-center align-items-center'>
                     <img className='img-fluid' src={Images.pageFound} alt="page Found" />
                  </div>
                  <h1 className='text-white text-center'>404</h1>
                  <strong className='d-flex justify-content-center'>Page Not Found</strong>
                  <Link className="not-found-logo d-flex justify-content-center align-items-center pt-3" to="/">
                     <Button>Go Back to Home</Button>
                  </Link>
               </Col>
            </Row>
         </Container>
      </div>
   )
}

export default NotFound