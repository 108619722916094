import React, { useState } from 'react';
import { Tab, Tabs, Table } from 'react-bootstrap'
import Select from 'react-select';
import './TransactionHistory.css'

const options = [
    { value: 'Deposit', label: 'Deposit' },
    { value: 'Transfer', label: 'Transfer' },
    { value: 'Withdraw', label: 'Withdraw' },
];
const optionsone = [
    { value: 'Past 30 days', label: 'Past 30 days' },
    { value: 'Post 10 Days', label: 'Post 10 Days' },
    { value: 'Post 7 Days', label: 'Post 7 Days' },
    { value: 'Post 5 Days', label: 'Post 5 Days' },
];
const optionstwo = [
    { value: 'All', label: 'All' },
    { value: 'New', label: 'New' },
    { value: 'Old', label: 'Old' },
];
const optionsthree = [
    { value: 'All', label: 'All' },
    { value: 'Verified', label: 'Verified' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Completed', label: 'Completed' },
];
function TransactionHistory(props) {
    const [selectedOption, setSelectedOption] = useState({ value: 'Deposit', label: 'Deposit' });
    const [selectedOptionOne, setSelectedOptionOne] = useState({ value: 'Past 30 days', label: 'Past 30 days' });
    const [selectedOptionTwo, setSelectedOptionTwo] = useState({ value: 'All', label: 'All' });
    const [selectedOptionThree, setSelectedOptionThree] = useState({ value: 'All', label: 'All' });

    return (
        <div className='transaction-history'>
            {/* <div className='wallet-overview main-tabs d-flex justify-content-between align-items-center'> */}
            <div className='heading-span'>
                <div className='wallet-heading d-flex justify-content-between align-items-start align-items-lg-end flex-column flex-lg-row'>
                    <h3 className="mb-3 mb-lg-0">START ACCOUNT</h3>
                </div>
            </div>
            {/* </div> */}
            <div className='account-tab'>
                <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="main-tabs-account history flex-wrap"
                >
                    <Tab className='account-tabs ' eventKey="home" title="CRYPTO">
                        {/* <div className='tabs-button  flex-wrap'>
                            <Button className='button-tab mb-3 mb-lg-0'>DEPOSIT</Button>
                            <Button className='button-tab mb-3 mb-lg-0'>WITHDRAW</Button>
                        </div> */}
                        <div className='all-select d-flex flex-wrap'>
                            <div className='select-history'>
                                <label>Type</label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    classNamePrefix="wallet-select"
                                    options={options}
                                />
                            </div>
                            <div className='select-history'>
                                <label>Time</label>
                                <Select
                                    defaultValue={selectedOptionOne}
                                    onChange={setSelectedOptionOne}
                                    classNamePrefix="wallet-select"
                                    options={optionsone}
                                />
                            </div>
                            <div className='select-history all-selects'>
                                <label>Asset</label>
                                <Select
                                    defaultValue={selectedOptionTwo}
                                    onChange={setSelectedOptionTwo}
                                    classNamePrefix="wallet-select"
                                    options={optionstwo}
                                />
                            </div>
                            <div className='select-history all-selects'>
                                <label>Status</label>
                                <Select
                                    defaultValue={selectedOptionThree}
                                    onChange={setSelectedOptionThree}
                                    classNamePrefix="wallet-select"
                                    options={optionsthree}
                                />
                            </div>
                            <div className='select-history'>
                                <label>TxID</label>
                                <input type="text" className="form-control history-input" placeholder="Enter TxID"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1" />
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Time</th>
                                    <th className='tabs-table-th'>Type</th>
                                    <th className='tabs-table-th'>Deposit wallet</th>
                                    <th className='tabs-table-th'>Asset</th>
                                    <th className='tabs-table-th'>Amount</th>
                                    <th className='tabs-table-th'>TxID</th>
                                    <th className='tabs-table-th'>
                                        <div className='action-buttons d-flex justify-content-center'>Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white line-bottom'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="profile" title="FIAT">
                        {/* <div className='tabs-button  flex-wrap'>
                            <Button className='button-tab  mb-3 mb-lg-0'>DEPOSIT</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>WITHDRAW</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>BUY</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>TRANSFER</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>SWAP</Button>
                        </div> */}
                        <div className='all-select d-flex '>
                            <div className='select-history'>
                                <label>Type</label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    classNamePrefix="wallet-select"
                                    options={options}
                                />
                            </div>
                            <div className='select-history'>
                                <label>Time</label>
                                <Select
                                    defaultValue={selectedOptionOne}
                                    onChange={setSelectedOptionOne}
                                    classNamePrefix="wallet-select"
                                    options={optionsone}
                                />
                            </div>
                            <div className='select-history all-select'>
                                <label>Asset</label>
                                <Select
                                    defaultValue={selectedOptionTwo}
                                    onChange={setSelectedOptionTwo}
                                    classNamePrefix="wallet-select"
                                    options={optionstwo}
                                />
                            </div>
                            <div className='select-history all-select'>
                                <label>Status</label>
                                <Select
                                    defaultValue={selectedOptionThree}
                                    onChange={setSelectedOptionThree}
                                    classNamePrefix="wallet-select"
                                    options={optionsthree}
                                />
                            </div>
                            <div className='select-history'>
                                <label>TxID</label>
                                <input type="text" className="form-control history-input" placeholder="Enter TxID"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1" />
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Time</th>
                                    <th className='tabs-table-th'>Type</th>
                                    <th className='tabs-table-th'>Deposit wallet</th>
                                    <th className='tabs-table-th'>Asset</th>
                                    <th className='tabs-table-th'>Amount</th>
                                    <th className='tabs-table-th'>TxID</th>
                                    <th className='tabs-table-th'>
                                        <div className='action-buttons d-flex justify-content-center'>Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white  line-bottom'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="contact" title="TRANSFER">
                        {/* <div className='tabs-button flex-wrap'>
                            <Button className='button-tab  mb-3 mb-lg-0'>DEPOSIT</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>WITHDRAW</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>BUY</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>TRANSFER</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>SWAP</Button>
                        </div> */}
                        <div className='all-select d-flex '>
                            <div className='select-history'>
                                <label>Type</label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    classNamePrefix="wallet-select"
                                    options={options}
                                />
                            </div>

                            <div className='select-history'>
                                <label>Time</label>
                                <Select
                                    defaultValue={selectedOptionOne}
                                    onChange={setSelectedOptionOne}
                                    classNamePrefix="wallet-select"
                                    options={optionsone}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Asset</label>
                                <Select
                                    defaultValue={selectedOptionTwo}
                                    onChange={setSelectedOptionTwo}
                                    classNamePrefix="wallet-select"
                                    options={optionstwo}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Status</label>
                                <Select
                                    defaultValue={selectedOptionThree}
                                    onChange={setSelectedOptionThree}
                                    classNamePrefix="wallet-select"
                                    options={optionsthree}
                                />
                            </div>

                            <div className='select-history'>
                                <label>TxID</label>
                                <input type="text" className="form-control history-input" placeholder="Enter TxID"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1" />
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Time</th>
                                    <th className='tabs-table-th'>Type</th>
                                    <th className='tabs-table-th'>Deposit wallet</th>
                                    <th className='tabs-table-th'>Asset</th>
                                    <th className='tabs-table-th'>Amount</th>
                                    <th className='tabs-table-th'>TxID</th>
                                    <th className='tabs-table-th'>
                                        <div className='action-buttons d-flex justify-content-center'>Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white  line-bottom'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="about" title="DISTRIBUTION">
                        {/* <div className='tabs-button flex-wrap'>
                            <Button className='button-tab  mb-3 mb-lg-0'>DEPOSIT</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>WITHDRAW</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>BUY</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>TRANSFER</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>SWAP</Button>
                        </div> */}
                        <div className='all-select d-flex '>
                            <div className='select-history'>
                                <label>Type</label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    classNamePrefix="wallet-select"
                                    options={options}
                                />
                            </div>

                            <div className='select-history'>
                                <label>Time</label>
                                <Select
                                    defaultValue={selectedOptionOne}
                                    onChange={setSelectedOptionOne}
                                    classNamePrefix="wallet-select"
                                    options={optionsone}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Asset</label>
                                <Select
                                    defaultValue={selectedOptionTwo}
                                    onChange={setSelectedOptionTwo}
                                    classNamePrefix="wallet-select"
                                    options={optionstwo}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Status</label>
                                <Select
                                    defaultValue={selectedOptionThree}
                                    onChange={setSelectedOptionThree}
                                    classNamePrefix="wallet-select"
                                    options={optionsthree}
                                />
                            </div>

                            <div className='select-history'>
                                <label>TxID</label>
                                <input type="text" className="form-control history-input" placeholder="Enter TxID"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1" />
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Time</th>
                                    <th className='tabs-table-th'>Type</th>
                                    <th className='tabs-table-th'>Deposit wallet</th>
                                    <th className='tabs-table-th'>Asset</th>
                                    <th className='tabs-table-th'>Amount</th>
                                    <th className='tabs-table-th'>TxID</th>
                                    <th className='tabs-table-th'>
                                        <div className='action-buttons d-flex justify-content-center'>Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white  line-bottom'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                    <Tab className='account-tabs mb-3 mb-sm-0' eventKey="contactus" title="TRANSFER">
                        {/* <div className='tabs-button flex-wrap'>
                            <Button className='button-tab  mb-3 mb-lg-0'>DEPOSIT</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>WITHDRAW</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>BUY</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>TRANSFER</Button>
                            <Button className='button-tab  mb-3 mb-lg-0'>SWAP</Button>
                        </div> */}
                        <div className='all-select d-flex '>
                            <div className='select-history'>
                                <label>Type</label>
                                <Select
                                    defaultValue={selectedOption}
                                    onChange={setSelectedOption}
                                    classNamePrefix="wallet-select"
                                    options={options}
                                />
                            </div>

                            <div className='select-history'>
                                <label>Time</label>
                                <Select
                                    defaultValue={selectedOptionOne}
                                    onChange={setSelectedOptionOne}
                                    classNamePrefix="wallet-select"
                                    options={optionsone}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Asset</label>
                                <Select
                                    defaultValue={selectedOptionTwo}
                                    onChange={setSelectedOptionTwo}
                                    classNamePrefix="wallet-select"
                                    options={optionstwo}
                                />
                            </div>

                            <div className='select-history all-select'>
                                <label>Status</label>
                                <Select
                                    defaultValue={selectedOptionThree}
                                    onChange={setSelectedOptionThree}
                                    classNamePrefix="wallet-select"
                                    options={optionsthree}
                                />
                            </div>

                            <div className='select-history'>
                                <label>TxID</label>
                                <input type="text" className="form-control history-input" placeholder="Enter TxID"
                                    aria-label="Example text with button addon" aria-describedby="button-addon1" />
                            </div>
                        </div>
                        <Table responsive>
                            <thead>
                                <tr className='text-white'>
                                    <th className='tabs-table-th'>Time</th>
                                    <th className='tabs-table-th'>Type</th>
                                    <th className='tabs-table-th'>Deposit wallet</th>
                                    <th className='tabs-table-th'>Asset</th>
                                    <th className='tabs-table-th'>Amount</th>
                                    <th className='tabs-table-th'>TxID</th>
                                    <th className='tabs-table-th'>
                                        <div className='action-buttons d-flex justify-content-center'>Status</div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                                <tr className='text-white line-bottom'>
                                    <td className='euro'>14:11:58</td>
                                    <td className='euro'>Deposit</td>
                                    <td className='euro'>212 000</td>
                                    <td className='euro'>All</td>
                                    <td className='euro'>3 000</td>
                                    <td className='euro'>2kk23981as0</td>
                                    <td className='table-button euro'>
                                        <div className='action-buttons d-flex justify-content-end'>Completed</div>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default TransactionHistory