import React, { useEffect } from "react";
import HeaderUnAuth from "../components/HeaderUnAuth/HeaderUnAuth";
import FooterUnAuth from '../components/FooterUnAuth/FooterUnAuth';

const Layout3 = (props) => {
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    const { children } = props;
    
    return (
        <div className="unauth-routes">
            <HeaderUnAuth />
            {children}
            <FooterUnAuth />
        </div>
    )
}

export default Layout3;