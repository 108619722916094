import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Images } from '../../assets/assets'
import './ExcchangeInstantly.css'

const ExcchangeInstantly = () => {
    return (
        <div className='excchange-instantly'>
            <Container>
                <div className='crypto-card-max-width'>
                    <Row className='d-flex align-items-center'>
                        <Col md={6}>
                            <div className='excchange-instantly-img'>
                                <img className='img-fluid' src={Images.excchange} alt="cexcchange" />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='heading-paragraphe'>
                                <h4 className='text-white'>Swap Spontaneously</h4>
                                <p>Users can swap their tokens into different VIP 180 based tokens without leaving the application itself</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}

export default ExcchangeInstantly